import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Slide,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link, useNavigate, useRouter } from '@tanstack/react-router';
import React, { FC, MouseEvent, useState } from 'react';

import { getPages } from '@/components/navigation/navPages';
import ProfileAvatar from '@/components/user/ProfileAvatar';
import { FeatureFlag } from '@/contexts/Settings.types';
import { useFetchData, usePostData } from '@/helpers/hooks';
import { cn } from '@/lib/utils';
import WeavrLogo from '@/public/images/weavr_logo.png';
import HamburgerClose from '@/public/svgs/HamburgerClose.svg?react';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import useGlobalSettingsActions from '@/store/useGlobalSettingsActions';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '../ui/navigation-menu';
import NavigationMenuListItem from './NavigationMenuListItem';
import { useVisiblePages } from '@/hooks/useNavigationVisiblePages';
import { useShallow } from 'zustand/react/shallow';
type MenuPopupProps = {
  isOpen: boolean;
  close: () => void;
  onDialogOpen: () => void;
};

const getMenuItems = (features: FeatureFlag[] | undefined) => {
  return [
    ...(features?.includes('CaseStudies')
      ? [
          {
            label: 'Case Studies',
            path: '/casestudies',
          },
        ]
      : []),
    ...(features?.includes('Blogs')
      ? [
          {
            label: 'Blogs',
            path: '/Blogs',
          },
        ]
      : []),

    {
      label: 'Resources',
      path: '',
      items: [
        { title: 'Terms and conditions', link: 'terms' },
        { title: 'Privacy policy', link: 'privacy_policy' },
        { title: 'Security', link: 'security' },
      ],
    },
  ];
};

const settings = ['Profile', 'Logout'];

const MenuPopup: FC<MenuPopupProps> = ({ isOpen, close, onDialogOpen }) => {
  const router = useRouter();
  const location = router.state.location;
  const navigate = useNavigate();

  const { isAuthenticated, globalSettings } = useGlobalSettingsStore(
    useShallow((state) => ({
      globalSettings: state.globalSettings,
      isAuthenticated: state.isAuthenticated,
    })),
  );

  const { updateSettings } = useGlobalSettingsActions();

  const { instance, accounts } = useMsal();

  const enabledFeatures = globalSettings.enabledFeatures;

  const pages = getPages(enabledFeatures);

  const visiblePages = useVisiblePages(pages, globalSettings.role);

  const logoutMutation = usePostData<boolean, unknown, unknown, unknown>({
    url: '/login/logout',
  });

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const normalizePath = (path: string) => {
    return path.split('/')[1].toLowerCase();
  };

  const isActivePage = (pagePath: string) => {
    const normalizedCurrentPath = normalizePath(location.pathname);
    const normalizedPagePath = normalizePath(pagePath);
    return normalizedCurrentPath === normalizedPagePath;
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogoutRedirect = async () => {
    const logoutRequest = {
      postLogoutRedirectUri: '/',
    };
    if (accounts.length > 0) {
      instance.logoutRedirect(logoutRequest);
    } else {
      const result = await logoutMutation.mutateAsync({});
      if (result) {
        window.location.href = '/';
      }
    }
  };

  const handleCloseUserMenu = (setting: unknown) => {
    setAnchorElUser(null);
    if (typeof setting !== 'string') return;
    switch (setting?.toLowerCase()) {
      case 'logout':
        handleLogoutRedirect();
        break;
      case 'profile':
        navigate({ to: '/profile' });
        break;
      default:
        console.warn(`Unknown setting: ${setting}`);
    }
  };

  const menuItems = getMenuItems(enabledFeatures);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      {isSmallScreen && (
        <Modal open={isOpen} sx={{ width: '100vw' }}>
          <Slide direction="right" in={isOpen}>
            <Box
              className={
                'rounded-8 flex h-full flex-col justify-between overflow-auto bg-white px-4 pb-14 md:overflow-hidden'
              }
            >
              <div>
                <div className="flex justify-between">
                  <div className="flex flex-row items-center justify-start">
                    <img
                      src={WeavrLogo}
                      alt="Weavr Logo"
                      width={50}
                      className="h-[50px]"
                    />
                    <Typography
                      variant="h6"
                      noWrap
                      component={Link}
                      to="/"
                      sx={{
                        display: { md: 'flex' },
                        fontWeight: 800,
                        fontSize: { xs: '2rem', md: '3.6rem' },
                        color: '#9c5915',
                        textDecoration: 'none',
                      }}
                    >
                      weavr.
                    </Typography>
                  </div>
                  <IconButton onClick={close}>
                    <HamburgerClose />
                  </IconButton>
                </div>
                <div className="px-2">
                  {!isAuthenticated ? (
                    <>
                      <div className="mt-6 flex flex-col">
                        {menuItems.map((item, index) => (
                          <React.Fragment key={index}>
                            <Link
                              to={item.path}
                              className="py-2 text-xl text-gray-800"
                              onClick={close}
                            >
                              {item.label}
                            </Link>
                            {item.items && (
                              <div className="ml-4 flex flex-col">
                                {item.items.map((subItem, subIndex) => (
                                  <Link
                                    key={subIndex}
                                    to={subItem.link}
                                    className="py-1 text-lg text-gray-600"
                                    onClick={close}
                                  >
                                    {subItem.title}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="mt-6">
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{ fontSize: '1.2rem' }}
                          onClick={onDialogOpen}
                        >
                          Let&apos;s yarn!
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 2,
                            backgroundColor: '#000',
                            fontSize: '1.2rem',
                          }}
                          onClick={() => instance.loginRedirect()}
                        >
                          Login
                        </Button>
                      </div>
                    </>
                  ) : (
                    <NavigationMenu>
                      <NavigationMenuList>
                        {visiblePages.map((page, index) => {
                          const pageName =
                            typeof page === 'string' ? page : Object.keys(page)[0];
                          if (
                            typeof page === 'string' ||
                            !Object.values(page)[0]?.itemSets
                          ) {
                            const pagePath = `/${pageName.toLowerCase()}`;
                            return (
                              <NavigationMenuItem key={pageName}>
                                <Link
                                  to={pagePath}
                                  className={cn(navigationMenuTriggerStyle())}
                                  activeProps={{
                                    className:
                                      'border-b-2 border-b-brown-dark bg-inherit text-accent-foreground',
                                  }}
                                  onClick={close}
                                >
                                  {pageName}
                                </Link>
                              </NavigationMenuItem>
                            );
                          } else {
                            const key = Object.keys(page)[0] as string;
                            const subPages = page[key];
                            return (
                              subPages && (
                                <NavigationMenuItem key={`${key}-${index}`}>
                                  <NavigationMenuTrigger
                                    className={cn({
                                      'border-b-2 border-b-brown-dark bg-accent text-accent-foreground':
                                        isActivePage(
                                          `/${key.replace(' ', '_').toLowerCase()}`,
                                        ),
                                    })}
                                  >
                                    {key}
                                  </NavigationMenuTrigger>
                                  <NavigationMenuContent>
                                    <div className="flex w-[88vw] overflow-auto md:w-[500px] lg:w-[600px]">
                                      <ul className="flex-grow p-4">
                                        {subPages.itemSets?.map((itemSet, setIndex) => {
                                          if (
                                            globalSettings.role &&
                                            itemSet.roles.includes(globalSettings.role)
                                          ) {
                                            return (
                                              <React.Fragment key={`itemset-${setIndex}`}>
                                                {setIndex > 0 && (
                                                  <Divider className="my-2" />
                                                )}
                                                {itemSet.items.map((item, itemIndex) => {
                                                  const isString =
                                                    typeof item === 'string';
                                                  const title = isString
                                                    ? item
                                                    : item.name;
                                                  const description = isString
                                                    ? undefined
                                                    : item.description;
                                                  const url = isString
                                                    ? undefined
                                                    : item.url;

                                                  return (
                                                    <NavigationMenuListItem
                                                      key={`${key}-${title}-${itemIndex}`}
                                                      title={title}
                                                      description={description}
                                                      to={
                                                        url
                                                          ? url
                                                          : `/${key.replace(' ', '_')}/${title
                                                              .toLowerCase()
                                                              .replace(' ', '_')}`
                                                      }
                                                      onClick={close}
                                                    >
                                                      {title}
                                                    </NavigationMenuListItem>
                                                  );
                                                })}
                                              </React.Fragment>
                                            );
                                          }
                                          return null;
                                        })}
                                      </ul>
                                      {subPages.image && (
                                        <div className="flex-shrink-0">
                                          <img
                                            src={subPages.image}
                                            className="h-auto w-full"
                                            alt={`${key} Section`}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </NavigationMenuContent>
                                </NavigationMenuItem>
                              )
                            );
                          }
                        })}
                      </NavigationMenuList>
                    </NavigationMenu>
                  )}
                  <Divider
                    sx={{
                      mt: 4,
                    }}
                  />
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', marginTop: 4, gap: 2 }}
                  >
                    {globalSettings?.organisationName !== undefined && (
                      <FormControl fullWidth>
                        <InputLabel>Impersonate</InputLabel>
                        <Select
                          label="Impersonate"
                          size={'small'}
                          value={globalSettings?.organisationName}
                          onChange={(e) => {
                            const newSettings = {
                              organisationName: e.target.value,
                            };
                            updateSettings(newSettings);
                          }}
                        >
                          {globalSettings.allOrganisations?.map((g) => (
                            <MenuItem value={g} key={g}>
                              {g}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {isAuthenticated && (
                      <>
                        {/* <Notification /> */}
                        <Box sx={{ flexGrow: 0, ml: 1 }}>
                          <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                              <ProfileAvatar alt="profile picture" />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                          >
                            {settings.map((setting) => (
                              <MenuItem
                                key={setting}
                                onClick={() => handleCloseUserMenu(setting)}
                              >
                                <Typography
                                  sx={{
                                    textAlign: 'center',
                                  }}
                                >
                                  {setting}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Menu>
                        </Box>
                      </>
                    )}
                  </Box>
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  variant="text"
                  sx={{ fontSize: '1rem', color: '#000', textTransform: 'none' }}
                >
                  Terms | Privacy policy
                </Button>
              </div>
            </Box>
          </Slide>
        </Modal>
      )}
    </>
  );
};

export default MenuPopup;
