import { zodResolver } from '@hookform/resolvers/zod';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { isEmpty, sumBy } from 'lodash-es';
import {
  forwardRef,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import ReactHookFormInput from '@/components/HookFormInput';
import { formatDateTimeToMonthString, isEndDateMatchPeriod } from '@/helpers/dateUtils';
import { usePostData, usePutData } from '@/helpers/hooks';
import { compareObjects } from '@/helpers/utils';
import ContributionCardContent from '@/pages/strategies/ContributionCardContent';
import HeaderDiv from '@/pages/strategies/HeaderDiv';
import ReportTypeIcon from '@/pages/strategies/Icons/ReportTypeIcon';
import ResponseTypeIcon from '@/pages/strategies/Icons/ResponseTypeIcon';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import {
  Contribution,
  ContributionCardProps,
  Periodicity,
  responseTypesNoOption,
} from '@/pages/strategies/types/Contribution.types';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { strategyItemFilters } from '@/types/itemFilter';
import { ResponseRequireConfirmation, ResponseWithValue } from '@/types/responseTypes';

import CardCountChip from './wrapperCard/CardCountChip';
import StyledCard from './wrapperCard/StyledCard';

const dropdownOptionSchema = z.object({ title: z.string(), id: z.string() });

const ContributionCard = memo(
  forwardRef<HTMLDivElement, ContributionCardProps>((props, ref) => {
    const {
      isActive,
      item,
      dropdownOptions,
      setAddingContribution,
      handleHeaderClick,
      setActiveContributions,
      isSelected,
      handleCheckboxClick,
      existedContributionOrder,
      businessUnits,
      disabled = false,
    } = props;

    const routeApi = getRouteApi('/strategies');
    const { deliverableId: primaryDeliverableId, contributionId: primaryContributionId } =
      routeApi.useSearch();

    const navigate = useNavigate({ from: routeApi.id });
    const updateFilters = useCallback(
      (name: keyof strategyItemFilters, value: unknown) => {
        navigate({ search: (prev: any) => ({ ...prev, [name]: value }) });
      },
      [navigate],
    );

    const allowEditStrategy = useStrategyStore(
      useShallow((state) => state.getAllowEditStrategy()),
    );

    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const { strategyStart, strategyEnd } = useStrategyStore(
      useShallow((state) => ({
        strategyStart: state.strategyStart,
        strategyEnd: state.strategyEnd,
      })),
    );
    const [pendingResponseType, setPendingResponseType] = useState<string | undefined>(
      undefined,
    );
    const formSchema = z
      .object({
        responseType: z.string(),
        responseOptions: z.array(dropdownOptionSchema.required()),
        name: z.string().min(1, 'Question is required'),
        reportTypeIds: z.string().array(),
        order: z.coerce
          .string()
          .min(1, 'Deliverable order is required')
          .refine(
            (value) =>
              !existedContributionOrder.filter((x) => x !== item.order).includes(value),
            'Order exists',
          ),
        newPeriodFrom: z
          .date()
          .min(
            dayjs(strategyStart).startOf('month').toDate(),
            'From date cannot be earlier than Strategy start date',
          )
          .optional(),
        newPeriodTo: z
          .date()
          .max(
            dayjs(strategyEnd).endOf('month').toDate(),
            'To date cannot be after than Strategy end date',
          )
          .optional(),
        newPeriodFrequency: z.nativeEnum(Periodicity).optional(),
        subIndicator: z.string().optional(),
        holdById: z.string().optional(),
        autoStatusUpdate: z.boolean().optional(),
      })
      .superRefine((data, ctx) => {
        if (item.isNew) {
          if (dayjs(data.newPeriodFrom).isBefore(strategyStart, 'month')) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `From date cannot be earlier than the Strategy Start Date.`,
              path: ['newPeriodFrom'],
            });
          }
          if (
            data.newPeriodFrequency === Periodicity.Once &&
            dayjs(data.newPeriodFrom).isAfter(strategyEnd, 'month')
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `From date cannot be later than the Strategy End Date when frequency is 'Once'.`,
              path: ['newPeriodFrom'],
            });
          }
          if (
            data.newPeriodFrequency !== Periodicity.Once &&
            !isEndDateMatchPeriod(
              data.newPeriodFrom!,
              data.newPeriodTo!,
              data.newPeriodFrequency!,
            )
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `To date mismatch with From date and Frequency.`,
              path: ['newPeriodTo'],
            });
          }
        } else {
          if (
            !responseTypesNoOption.includes(data.responseType) &&
            data.responseOptions.length <= 0
          )
            if (pendingResponseType)
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `New Response Type will not be saved until at least one response option is added.`,
                path: ['responseOptions'],
              });
            else
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Response option is required.`,
                path: ['responseOptions'],
              });
        }
        return z.NEVER;
      });

    type ContributionFormFields = z.infer<typeof formSchema>;

    const defaultValues: ContributionFormFields = useMemo(
      () => ({
        responseType: item.responseType,
        responseOptions: item.responseOptions,
        name: item.name ?? '',
        order: item.order ?? 0,
        reportTypeIds: item.reportTypeIds,
        newPeriodFrom: item.isNew ? dayjs(strategyStart).startOf('month').toDate() : undefined,
        newPeriodTo: item.isNew ? dayjs(strategyEnd).endOf('month').toDate() : undefined,
        newPeriodFrequency: item.isNew
          ? Periodicity.Once
          : (undefined as Periodicity | undefined),
        subIndicator: item.contributionSubIndicator?.toLowerCase() || '',
        holdById: item.holdById ?? '',
        autoStatusUpdate: item.autoStatusUpdate,
      }),
      [item],
    );

    const methods = useForm<ContributionFormFields>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(formSchema),
      defaultValues: defaultValues,
    });
    const { handleSubmit, formState, setValue, reset } = methods;

    const addContributionMutation = usePostData<
      ResponseRequireConfirmation | ResponseWithValue,
      unknown,
      ContributionFormFields,
      string
    >({ url: `/Contribution/AddContribution?deliverableId=${primaryDeliverableId}` });

    const updateContributionMutation = usePutData<
      ResponseRequireConfirmation,
      unknown,
      Partial<Contribution>,
      unknown
    >(`/Contribution/UpdateContribution`);

    const onBlur = async (data: ContributionFormFields) => {
      if (disabled) return;

      if (!item.isNew) {
        let dirtyFields = compareObjects(
          formState.defaultValues as ContributionFormFields,
          data,
        );
        if (!isEmpty(dirtyFields)) {
          if (dirtyFields.responseType !== null) {
            if (
              !responseTypesNoOption.includes(dirtyFields.responseType!) &&
              !dirtyFields.responseOptions
            ) {
              setPendingResponseType(dirtyFields.responseType);
              setValue('responseOptions', []);

              const { responseType: _responseType, ...updatedDirtyFields } = dirtyFields;
              if (isEmpty(updatedDirtyFields)) return;
              dirtyFields = updatedDirtyFields;
            }
            setPendingResponseType(undefined);
          }
          const payload = { id: item.id, ...dirtyFields } as Partial<Contribution>;
          await updateContributionMutation.mutateAsync(payload, {
            onSuccess: async (res) => {
              if (res.requireConfirmation) {
                const confirmed = await confirm({
                  title: 'Confirm',
                  message:
                    'This Contribution has been included in a Progress Update that has been submitted. Are you sure you want to update this?',
                });
                if (confirmed) {
                  const confirmedPayload = { userConfirmed: true, ...payload };
                  await updateContributionMutation.mutateAsync(confirmedPayload);
                } else {
                  setValue('name', item.name);
                }
              }
              await queryClient.invalidateQueries({
                queryKey: ['strategyData'],
              });
            },
            onError: async () => {
              reset({ ...defaultValues });
            },
          });
        }
      }
    };

    const onSubmit = async (data: ContributionFormFields) => {
      if (disabled) return;

      if (item.isNew) {
        const payload = {
          id: item.id,
          reportTypeIds: data.reportTypeIds,
          responseOptions: data.responseOptions,
          responseType: data.responseType,
          order: data.order,
          name: data.name,
          newPeriodFromDate: formatDateTimeToMonthString(data.newPeriodFrom as Date),
          newPeriodToDate: formatDateTimeToMonthString(data.newPeriodTo as Date),
          newPeriodFrequency: data.newPeriodFrequency,
          subIndicator: data.subIndicator,
          autoStatusUpdate: data.autoStatusUpdate,
        } as ContributionFormFields;
        if (data.holdById) {
          payload.holdById = data.holdById;
        }
        const handleSuccessfulAddition = async (value: string) => {
          await queryClient.invalidateQueries({ queryKey: ['strategyData'] });
          handleHeaderClick({ ...item, id: value });
          setAddingContribution?.(null);
        };
        await addContributionMutation.mutateAsync(payload, {
          onSuccess: async (result) => {
            if (
              'requireConfirmation' in result &&
              result.requireConfirmation &&
              result.message?.includes('are already Approved')
            ) {
              const confirmed = await confirm({
                title: 'Confirm Update',
                message: result.message,
              });
              if (confirmed) {
                const confirmedPayload = { confirmApproved: true, ...payload };
                await addContributionMutation.mutateAsync(confirmedPayload, {
                  onSuccess: async (
                    res: ResponseRequireConfirmation | ResponseWithValue,
                  ) => {
                    if ('value' in res) {
                      await handleSuccessfulAddition(res.value as any as string);
                    }
                    setAddingContribution?.(null);
                  },
                });
              }
            } else if ('value' in result) {
              handleHeaderClick({ ...item, id: result.value as any as string });
              setAddingContribution?.(null);
            }
          },
        });
      }
    };

    useEffect(() => {
      if (formState.isSubmitSuccessful) {
        methods.reset({ ...defaultValues });
      }
    }, [defaultValues, methods]);
    const handleLastActionClick = (
      event: MouseEvent<HTMLElement> | null,
      id?: string,
    ) => {
      if (isActive && !item.isNew) {
        updateFilters('actionId', item.actionId);
        updateFilters('deliverableId', item.deliverableId);
        updateFilters('contributionId', item.id);
      }
      if (item.isNew && id) {
        updateFilters('actionId', item.actionId);
        updateFilters('deliverableId', item.deliverableId);
        updateFilters('contributionId', id);
      }
    };

    const progressStatusCount = useMemo(() => {
      return sumBy(item.contributionPeriods, (x) => x.progressResponseCount ?? 0);
    }, [item.contributionPeriods]);

    const reportTypeId = item.reportTypeIds[0];

    const reportTypes = useMemo(() => {
      return dropdownOptions?.reportTypes?.find((x) => x.id === reportTypeId)?.title;
    }, [dropdownOptions, reportTypeId]);

    return (
      <FormProvider {...methods}>
        <form
          onBlur={() => {
            if (!item.isNew) handleSubmit(onBlur)();
          }}
          onSubmit={handleSubmit(onSubmit)}
          id="contribution-form"
        >
          <StyledCard
            isActive={isActive}
            className={
              primaryContributionId === item.id || item.isNew ? 'gradient-border' : ''
            }
            ref={ref}
          >
            <Tooltip
              title={
                item.isActive ? (
                  ''
                ) : (
                  <span style={{ fontSize: 14 }}>
                    You cannot edit this contribution as it is currently inactive.
                  </span>
                )
              }
            >
              <CardContent
                sx={{
                  backgroundColor: !item.isActive && isActive ? 'lightgray' : 'inherit',
                  padding: '0!important',
                  containerType: 'inline-size',
                }}
              >
                <HeaderDiv
                  style={{
                    gap: '4px',
                    justifyContent: 'flex-start',
                  }}
                >
                  {!item.isNew &&
                    (item.contributionPeriods.length === 1 ? (
                      <Checkbox
                        onMouseDown={(e) => handleLastActionClick(e)}
                        checked={isSelected}
                        onChange={() => handleCheckboxClick?.(item.id)}
                        disabled={!allowEditStrategy || !item.isActive || disabled}
                      ></Checkbox>
                    ) : (
                      <Tooltip
                        title={
                          'Bulk updating is not allowed for contributions with multiple periods.'
                        }
                      >
                        <div>
                          <Checkbox disabled></Checkbox>
                        </div>
                      </Tooltip>
                    ))}

                  {isActive || (item.isNew ?? false) ? (
                    <div style={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: 2,
                          alignItems: 'center',

                          containerType: 'inline-size',
                          '@container (max-width: 400px)': {
                            flexDirection: 'column',
                          },
                        }}
                      >
                        <ReactHookFormInput
                          onMouseDown={handleLastActionClick}
                          name={'order'}
                          label={'Order'}
                          prefix={`${item.actionOrder}.${item.deliverableOrder}.`}
                          disabled={!allowEditStrategy || !item.isActive || disabled}
                          sx={{ maxWidth: '10ch' }}
                        />
                        <ReactHookFormInput
                          onMouseDown={handleLastActionClick}
                          name={'name'}
                          label={'Name'}
                          disabled={!allowEditStrategy || !item.isActive || disabled}
                          multiline={true}
                          minRows={1}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Typography
                      flex={1}
                      maxWidth={'85%'}
                      color={!item.holdById || !!item.achievedDate ? 'inherit' : 'error'}
                    >
                      {item.actionOrder}.{item.deliverableOrder}.{item.order} {item.name}
                    </Typography>
                  )}
                  <div className="ml-auto flex shrink-0 items-center gap-2 pl-1">
                    {!isActive && (
                      <>
                        {item.reportTypeIds.length === 1 ? (
                          <ReportTypeIcon reportType={reportTypes} />
                        ) : (
                          item.reportTypeIds.length > 1 && (
                            <CardCountChip
                              count={item.reportTypeIds.length}
                              title="Report types"
                            />
                          )
                        )}
                        <CardCountChip
                          count={progressStatusCount}
                          title="Total Progress Updates"
                        />

                        {item.contributionPeriods.length !== 1 && (
                          <CardCountChip
                            title="Periods"
                            count={item.contributionPeriods.length}
                            bgColor="#ADD8E6"
                          />
                        )}
                        <ResponseTypeIcon responseType={item.responseType} />
                      </>
                    )}
                    <IconButton
                      onClick={() => {
                        handleHeaderClick(item);
                      }}
                    >
                      {isActive ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                </HeaderDiv>

                <Collapse
                  in={isActive || (item.isNew ?? false)}
                  mountOnEnter
                  unmountOnExit
                >
                  <ContributionCardContent
                    disabled={!allowEditStrategy || !item.isActive || disabled}
                    item={item}
                    dropdownOptions={dropdownOptions}
                    setActiveContributions={setActiveContributions}
                    handleLastActionClick={(e) => handleLastActionClick(e)}
                    onBlurParent={() => handleSubmit(onBlur)()}
                    showSaveIcon={true}
                    businessUnits={businessUnits}
                  />
                </Collapse>
              </CardContent>
            </Tooltip>
          </StyledCard>
        </form>
      </FormProvider>
    );
  }),
  (prev, next) =>
    prev.item === next.item &&
    prev.isActive == next.isActive &&
    prev.disabled == next.disabled,
);

export default ContributionCard;
