import { Circle, PriorityHigh } from '@mui/icons-material';
import AutoStories from '@mui/icons-material/AutoStories';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import CreateIcon from '@mui/icons-material/Create';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WarningIcon from '@mui/icons-material/Warning';
import {
  AppBar,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { debounce } from 'lodash-es';
import Image from 'mui-image';
import React, { useCallback, useEffect, useState } from 'react';

import { formatRelativeTime } from '@/helpers/dateUtils';
import { usePostData, usePutData } from '@/helpers/hooks';
import { getMentionStyledText } from '@/helpers/utils';
import { NotificationItem } from '@/pages/Notification';
import { useConfirm } from '@/store/useConfirmDialogStore';

type Props = {
  open: boolean;
  notifications: NotificationItem[];
};

type Notification = {
  id: string;
};

export default function NotificationSidebar({ open, notifications }: Props) {
  const [imageUrlToShow, setImageUrlToShow] = useState<string | undefined>(undefined);

  const markAllAsRead = usePostData<unknown>({
    url: '/notification/MarkAllAsRead',
  });
  const deleteAll = usePostData<unknown>({
    url: '/notification/DeleteAll',
  });
  const markNotificationAsRead = usePutData<unknown, unknown, Notification>(
    '/notification/MarkAsRead',
  );

  const queryClient = useQueryClient();

  const [filter, setFilter] = useState<'All' | 'Unread'>('All');

  const [navbarHeight, setNavbarHeight] = useState(0);
  const [height, setHeight] = useState('100vh');

  const updateDimensions = useCallback(() => {
    const navbar = document.querySelector('.sticky.top-0') as HTMLElement;
    if (navbar) {
      const newNavbarHeight = navbar.offsetHeight;
      setNavbarHeight(newNavbarHeight);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      setHeight(`calc(var(--vh, 1vh) * 100 - ${newNavbarHeight}px)`);
    }
  }, []);

  useEffect(() => {
    const debouncedUpdateDimensions = debounce(updateDimensions, 250);

    updateDimensions(); // Initial call
    window.addEventListener('resize', debouncedUpdateDimensions);

    return () => {
      window.removeEventListener('resize', debouncedUpdateDimensions);
    };
  }, [updateDimensions]);

  const handleFilterChange = (newFilter: 'All' | 'Unread') => {
    setFilter(newFilter);
  };

  const notificationClicked = (notification: NotificationItem) => {
    if (!notification.isRead) {
      markNotificationAsRead.mutate(
        { id: notification.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['notifications'],
            });
            if (notification.url?.length) {
              if (notification.url.toLocaleLowerCase().includes('weavrmedia')) {
                setImageUrlToShow(notification.url);
              } else {
                window.open(notification?.url, '_blank');
              }
            }
          },
        },
      );
    } else {
      if (notification.url?.length) {
        if (notification.url.toLocaleLowerCase().includes('weavrmedia')) {
          setImageUrlToShow(notification.url);
        } else {
          window.open(notification?.url, '_blank');
        }
      }
    }
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  const filteredNotifications =
    filter === 'All'
      ? notifications
      : notifications.filter((notification) => !notification.isRead);

  const handleMarkAllAsRead = () => {
    markAllAsRead.mutate(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      },
    });
  };
  const confirm = useConfirm();
  const handleDeleteAll = async () => {
    const res = await confirm({
      title: 'Delete notifications',
      message: 'Are you sure you want to delete the notifications?',
    });
    if (res) {
      deleteAll.mutate(undefined, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['notifications'],
          });
        },
      });
    }
  };

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Slide
      direction="left"
      in={open}
      mountOnEnter
      unmountOnExit
      className="fixed rounded border border-secondary bg-white"
      style={{
        right: 0,
        top: navbarHeight,
        marginBottom: '55px',
        height: height,
        width: isSmallScreen ? '100%' : '700px', // Full width for mobile screens
        zIndex: 1,
        overflowY: 'auto',
      }}
    >
      <div>
        <div className="ml-3 mt-3 flex items-center justify-between">
          <div>
            <Chip
              label="All"
              clickable
              color={filter === 'All' ? 'primary' : 'default'}
              onClick={() => handleFilterChange('All')}
              style={{ marginRight: '8px' }}
            />
            <Chip
              label="Unread"
              clickable
              color={filter === 'Unread' ? 'primary' : 'default'}
              onClick={() => handleFilterChange('Unread')}
            />
          </div>
          <div className="mr-3 flex gap-2">
            <Chip
              label="Delete all"
              clickable
              color="error"
              variant="outlined"
              onClick={handleDeleteAll}
            />
            <Chip
              label="Mark all as read"
              clickable
              color="info"
              variant="outlined"
              onClick={handleMarkAllAsRead}
            />
          </div>
        </div>
        <List>
          {filteredNotifications?.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ paddingInline: 1.5 }}
                  onClick={() => notificationClicked(notification)}
                >
                  <ListItemIcon sx={{ padding: 0, minWidth: '1.8rem' }}>
                    <Circle
                      sx={{ fontSize: '1rem' }}
                      color={!notification.isRead ? 'error' : 'disabled'}
                    />
                  </ListItemIcon>
                  <ListItemIcon sx={{ padding: 0, minWidth: '2rem' }}>
                    {notification.notificationType === 'ProgressComment' && (
                      <CommentIcon />
                    )}
                    {notification.notificationType === 'GoodNewsComment' && (
                      <AutoStories />
                    )}
                    {notification.notificationType === 'UserRegistration' && (
                      <PersonAddIcon />
                    )}
                    {notification.notificationType === 'ProgressUpdateCreated' && (
                      <CreateIcon />
                    )}
                    {notification.notificationType === 'ProgressUpdateSubmissionDue' && (
                      <PriorityHigh color="warning" />
                    )}
                    {notification.notificationType === 'ProgressUpdateActionRequired' && (
                      <WarningIcon color="error" />
                    )}
                    {notification.notificationType === 'ProgressUpdatePendingReview' && (
                      <PriorityHigh color="info" />
                    )}
                    {notification.notificationType === 'NewFeature' && (
                      <PriorityHigh color="info" />
                    )}
                  </ListItemIcon>
                  {notification.notificationType !== 'GoodNewsComment' &&
                  notification.notificationType !== 'ProgressComment' ? (
                    <div>
                      <ListItemText
                        primary={notification.title}
                        primaryTypographyProps={{
                          sx: { fontSize: '0.8rem', color: 'var(--secondary)' },
                        }}
                        secondary={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: '1rem' }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(notification.message),
                            }}
                          />
                        }
                        secondaryTypographyProps={{
                          sx: { fontSize: '1rem', color: 'black' },
                        }}
                      />
                      {notification.url ? (
                        <img
                          className="block w-full max-w-[240px] rounded-lg"
                          src={notification.url}
                          alt={notification.title}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <ListItemText
                        primary={
                          <Typography component="span">
                            <Box
                              component="span"
                              sx={{
                                fontWeight: 'fontWeightBold',
                              }}
                            >
                              {notification.createdBy}
                            </Box>
                            {` ${notification.title}`}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: '1rem' }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                getMentionStyledText(notification.message),
                              ),
                            }}
                          />
                        }
                      ></ListItemText>
                    </>
                  )}
                  <div className="shrink-0 self-start text-nowrap pl-2">
                    {formatRelativeTime(notification.createdDate)}
                  </div>
                </ListItemButton>
              </ListItem>

              {index !== notifications.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>

        {imageUrlToShow ? (
          <Dialog
            open={!!imageUrlToShow}
            onClose={() => setImageUrlToShow(undefined)}
            fullScreen
            TransitionComponent={Transition}
            className="border-4 border-black"
          >
            <AppBar sx={{ alignItems: 'end' }}>
              <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setImageUrlToShow(undefined)}
                  aria-label="close"
                  sx={{ ml: 'auto' }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{ marginTop: '60px' }}>
              <Image
                src={imageUrlToShow}
                alt="Uploaded event"
                duration={200}
                fit="scale-down"
              />
            </DialogContent>
          </Dialog>
        ) : null}
      </div>
    </Slide>
  );
}
