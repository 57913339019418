import { FeatureFlag, Role } from '@/contexts/Settings.types';

export type MenuItem =
  | string
  | {
      name: string;
      description?: string;
      url?: string;
      roles?: Role[];
    };

type ItemSet = {
  items: MenuItem[];
  roles: Role[];
};

export type Page =
  | string
  | Record<
      string,
      {
        itemSets?: ItemSet[];
        image?: string;
        roles?: Role[];
        showAtEnd?: boolean;
        url?: string;
      }
    >;

export const getPages = (features: FeatureFlag[] | undefined): Page[] => {
  return [
    'Home',
    {
      Strategies: {
        roles: [Role.Designer, Role.Admin, Role.GlobalAdmin, Role.Collaborator],
      },
    },
    {
      Collaborate: {
        roles: [Role.Collaborator, Role.Designer, Role.Admin, Role.GlobalAdmin],
      },
    },
    {
      Report: {
        itemSets: [
          {
            items: [
              {
                name: 'Our Journey',
                description: 'A report that shows the overall journey of the strategy.',
              },
              {
                name: 'Our Calendar',
                description: 'Display your contributions and periods.',
                roles: [Role.Collaborator, Role.Designer, Role.Admin, Role.GlobalAdmin],
              },
              {
                name: 'Our Updates',
                description: 'Find everything about the user activities.',
                roles: [Role.Collaborator, Role.Designer, Role.Admin, Role.GlobalAdmin],
              },
              {
                name: 'Our Songline',
                description: '',
              },
              {
                name: 'Photo Album',
                description: '',
              },
            ],
            roles: [
              Role.Collaborator,
              Role.Designer,
              Role.Admin,
              Role.GlobalAdmin,
              Role.LightUser,
            ],
          },
        ],
      },
    },
    {
      Admin: {
        itemSets: [
          {
            items: [
              {
                name: 'Users',
                description: 'Manage users.',
              },
              {
                name: 'Business Units',
                description: 'Manage business units.',
              },
              {
                name: 'Subscription',
                description: 'Manage subscription.',
              },
            ],
            roles: [Role.Admin, Role.GlobalAdmin],
          },
          {
            items: [
              {
                name: 'WeavRway SoS',
                description: 'Power BI Report',
                url: '/global_admin/weavrway_sos',
              },
              {
                name: 'Uploads',
                description: 'Manage uploads.',
                url: '/global_admin/uploads',
              },
              {
                name: 'All Subscriptions',
                description: 'Manage All Subscriptions.',
                url: '/global_admin/subscriptions',
              },
              {
                name: 'All Users',
                description: 'Manage All Users.',
                url: '/global_admin/users',
              },
              {
                name: 'App Lookups',
                description: 'Manage system configurations',
                url: '/global_admin/app_lookups',
              },
              {
                name: 'App Modules',
                description: 'Manage system modules',
                url: '/global_admin/app_modules',
              },
              {
                name: 'Scheduler',
                description: 'Scheduler',
                url: '/global_admin/scheduler',
              },
              {
                name: 'Content Builder',
                description: 'Content Builder',
                url: '/global_admin/content_builder',
              },
              {
                name: 'System',
                description: 'System Info',
                url: '/global_admin/system',
              },
            ],
            roles: [Role.GlobalAdmin],
          },
        ],
        roles: [Role.Admin, Role.GlobalAdmin],
      },
    },
    {
      Resources: {
        itemSets: [
          {
            items: [
              { name: 'Terms and conditions', url: '/terms' },
              { name: 'Privacy policy', url: '/privacy_policy' },
              { name: 'Security', url: '/security' },
              ...(features?.includes('CaseStudies')
                ? [{ name: 'Case Studies', url: '/casestudies' }]
                : []),
              ...(features?.includes('Blogs') ? [{ name: 'Blogs', url: '/blogs' }] : []),
              ...(features?.includes('KnowledgeBase')
                ? [
                    {
                      name: 'Knowledge Base',
                      url: '/knowledgebase',
                    },
                  ]
                : []),
            ],
            roles: [
              Role.Collaborator,
              Role.Designer,
              Role.Admin,
              Role.GlobalAdmin,
              Role.LightUser,
            ],
          },
        ],
      },
    },
  ];
};
